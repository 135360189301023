'use client';
import { useEnv } from '@/hooks/useEnv';
import { LaMesaRecVanLogo } from '@lamesarv-sdk/components';
import { IBlogCategoryData } from '@lamesarv-sdk/types';

import { BlogCard } from '../BlogCard';

interface ICategoryBlockProps {
  category: IBlogCategoryData;
}

const OldCategoryBlock = (props: ICategoryBlockProps) => {
  return (
    <a className="w-full h-full p-3" href={`/blog/category/${props.category.slug}`}>
      <div className="h-full rounded-xl border p-6 shadow-xl hover:shadow-lg hover:bg-blue-50">
        <div className="relative mb-6 h-52">
          {props.category.imageUrl && (
            <img className="h-full w-full rounded-lg object-cover" src={props.category.imageUrl} alt="" />
          )}
          {!props.category.imageUrl && <LaMesaRecVanLogo className="h-full w-full p-4" />}
        </div>
        <h2 className="text-left font-heading text-sage-700 mb-4 text-2xl font-semibold">{props.category.name}</h2>
        <p className="text-left mb-4 leading-relaxed text-gray-500">{props.category.description}</p>
        <div className="text-left text-sage-700 hover:text-blue-500 hover:decoration-blue-500 text-xs font-medium uppercase no-underline hover:underline hover:underline-offset-4">
          View Posts
        </div>
      </div>
    </a>
  );
};

const NewCategoryBlock = ({ category }: ICategoryBlockProps) => (
  <BlogCard
    link={`/blog/category/${category.slug}`}
    imageUrl={category.imageUrl}
    badge={category.name}
    text={category.description}
  />
);

export const CategoryBlock = (props: ICategoryBlockProps) => {
  const { env } = useEnv();

  if (env.ENABLE_NEW_BLOG_DESIGN) return <NewCategoryBlock {...props} />;

  return <OldCategoryBlock {...props} />;
};
